import * as React from "react";
import Home from "../pages/home";
import Error404 from "../pages/404";

export const routesConfig = [
    {
        name: "Home",
        class: "home",
        group: 'page',
        path: "/",
        title: "ÉNÉSÉN Music",
        element: <Home />
    },
    {
        name: "404",
        class: "error",
        group: 'page',
        path: "*",
        title: "404",
        element: <Error404 />
    },
    {
        name: 'nkor',
        class: 'album',
        group: 'album',
        type: "album",
        title: 'nKor',
        path: '#'
    },
    {
        name: 'kiadhatatlan',
        class: 'album',
        group: 'album',
        type: "album",
        title: 'Kiadhatalan',
        path: '#'
    },
];
