import React, {useEffect, useState, useRef} from "react";
import {motion, AnimatePresence, useAnimation} from "framer-motion";
import {Link, useLocation} from "react-router-dom";
import Icon from "./Icons";
import {routesConfig} from "../config/routes";

function Header({currentPage}) {
    return (
        <>
            <header className="header">
                <Icon name="enesen-logo" classList="logo"/>
                <Link to="https://www.instagram.com/enesen_music" key="instagram" target="_blank">
                    Instagram
                </Link>
            </header>
        </>
    );
}

export default Header;
