import React, {useEffect, useState, useRef} from "react";
import {motion, AnimatePresence, useAnimation} from "framer-motion";
import {Link, useLocation} from "react-router-dom";
import Icon from "./Icons";
import {routesConfig} from "../config/routes";

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <footer className="footer">
                <p>© {currentYear} ÉNÉSÉN Music.  All rights reserved.</p>
            </footer>
        </>
    );
}

export default Footer;
