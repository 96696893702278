import React, {useState, useEffect, CSSProperties} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {motion, useAnimationControls} from "framer-motion";
import {pageTransitionDuration, pageTransitionDelay} from "../transitions/TransitionConstants";
import Icon from "./Icons";

export const pageTransitionVariants = {
    hidden: {
        opacity: 0,
        transition: {
            duration: pageTransitionDuration
        }
    },
    visible: {
        opacity: 1,
        transition: {
            // delay: pageTransitionDelay,
            duration: pageTransitionDuration
        }
    },
    exit: {
        opacity: 0,
        transition: {
            delay: pageTransitionDuration,
            duration: pageTransitionDuration
        }
    }
}

export const loadingOverlayInVariants = {
    initial: {
        scaleY: 0,
        // opacity: 0
    },
    animate: {
        scaleY: 0,
        // opacity: .2,
        transition: {
            delay: pageTransitionDuration,
            duration: pageTransitionDuration,
            ease: [0.22, 1, 0.36, 1]
        }
    },
    exit: {
        scaleY: 1,
        // opacity: .7,
        transition: {
            duration: pageTransitionDuration
        }
    }
}
export const loadingOverlayOutVariants = {
    initial: {
        scaleY: 1,
        // opacity: .7,
    },
    animate: {
        scaleY: 0,
        // opacity: .2,
        transition: {
            delay: pageTransitionDelay,
            duration: pageTransitionDuration,
            ease: [0.22, 1, 0.36, 1]
        }
    },
    exit: {
        scaleY: 0,
        // opacity: .2,
        transition: {
            delay: pageTransitionDuration
        }
    }
}

const PRELOAD = {
    albums: [
        {url: '/images/projects/bt-home@2x.min.png'},
        {url: '/images/projects/wrapless-home@2x.min.png'},
        {url: '/images/projects/quick-pay-home@2x.min.png'},
    ]
}

const PageTransition = ({children}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const controls = useAnimationControls();
    const currentPage = children.props.page;
    const assets = PRELOAD[currentPage];
    const htmlRoot = document.getElementsByTagName("html")[0];

    // Add page name to HTML tag on first load
    if (!htmlRoot.getAttribute("loading")) {
        htmlRoot.setAttribute("loading", !isLoaded);
    }

    useEffect(() => {
        if (isLoaded) {
            controls.start('exit');
            // Scroll top page on transition buy delay it by the start delay
            window.setTimeout(() => window.scrollTo({ top:0, left:0, behavior: "instant"}), pageTransitionDuration * 1000);
        }
        htmlRoot.setAttribute("loading", !isLoaded);
    }, [isLoaded])

    useEffect(() => {
        if (!assets) {
            setIsLoaded(true);
            return;
        }

        const loadImage = image => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image()
                loadImg.src = image.url
                // wait 2 seconds to simulate loading time on dev env
                if (process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    loadImg.onload = () => {
                        setTimeout(() => resolve(image.url), 2000)
                    }
                } else {
                    loadImg.onload = () => {
                        resolve(image.url);
                    }
                }
                // loadImg.onload = () => {
                //     resolve(image.url);
                // }
                loadImg.onerror = err => reject(err);
            })
        }

        Promise.all(assets.map(image => loadImage(image)))
            .then(() => {
                setIsLoaded(true)
            })
            .catch(err => console.log("Failed to load images", err))
    }, [])

    return (
        <>
            {isLoaded ? children : <></>}
            <motion.div
                className="page-transition-overlay"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={loadingOverlayInVariants}
            />
            <motion.div
                className="page-transition-overlay page-transition-overlay--out"
                initial="initial"
                animate={controls}
                exit="exit"
                variants={loadingOverlayOutVariants}
            >
                <div className={`${isLoaded ? 'page-loader page-loader--hidden' : 'page-loader'}`}>
                    <Icon name="logo" classList="logo page-loader__logo icon--huge"/>
                    <ClipLoader
                        loading={!isLoaded}
                        className="page-loader__loader"
                        color="var(--color-accent-primary)"
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    <p className="page-loader__text">Loading</p>
                </div>
            </motion.div>

        </>
    )
}

export default PageTransition;
