import React, {useRef, useEffect, useState} from "react";
import { Helmet } from 'react-helmet-async';

const Error404 = () => {
    const ref = useRef(null);

    return (
        <>
            <Helmet>
                <title>404</title>
            </Helmet>
            404
        </>
    );
}

export default Error404;
