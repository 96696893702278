import React, {useRef, useEffect, useState} from "react";
import {isMobile} from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    MotionValue, useInView, useAnimation
} from "framer-motion";
import { Link } from "react-router-dom";
import pageTransition, {pageTransitionVariants} from "../components/PageTransition";
import {routesConfig} from "../config/routes";
import AnimatedLetters from "../transitions/AnimatedLetters";
import SlideTransition from "../transitions/SlideTransition";
import {pageTransitionDelay, verticalScrollOffsetMarginLarge} from "../transitions/TransitionConstants";
import Icon from "../components/Icons";
import Footer from "../components/Footer";

function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
}

function Slide({ data }) {
    const ref = useRef(null);
    const isInView = useInView(ref, {margin: verticalScrollOffsetMarginLarge});
    const [slideActive, setSlideActive] = useState(0);
    const { scrollYProgress } = useScroll({ target: ref });
    const y = useParallax(scrollYProgress, isMobile ? 120 : 140);
    const htmlRoot = document.getElementsByTagName("html")[0];
    const path = data.path;

    const controls = useAnimation();

    const imageVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1
        }
    }

    const dataListVariants = {
        hidden: {
            transition: {
                staggerChildren: 0.1,
                staggerDirection: -1
            }
        },
        visible: {
            transition: {
                delayChildren: .75,
                staggerChildren: 0.1,
                staggerDirection: 1
            }
        }
    };

    const dataListItemVariants = {
        hidden: {
            opacity: 0,
            top: -40
        },
        visible: {
            opacity: 1,
            top: 0
        }
    };

    useEffect(() => {
        if (isInView) {
            setSlideActive(true);
            controls.start("visible");
            htmlRoot.setAttribute("section", data.title.toLowerCase());
        } else {
            controls.start("hidden");
            setSlideActive(false);
        }
    }, [ref, controls, isInView]);

    return (
        <>
            <Helmet>
                <title>ÉNÉSÉN Music</title>
            </Helmet>
            <section className={`home__section home__section--${data.title.toLowerCase()} ${slideActive ? 'home__section--active' : ''}`} ref={ref}>
                {isMobile ? (
                    <>
                        <div className="home__section-background">
                            <img src={data.image}
                                 srcSet={`${data.image2x ? data.image2x + " 2x" : ""}`}
                                 alt={`Title: ${data.title}`} />
                        </div>
                        <div className="home__section-background home__section-background--graphics">
                            <img src={data.svg} style={{transform: "rotate("+data.svgrotate+"deg)"}} />
                        </div>
                    </>
                ) : (
                    <>
                        <motion.div className="home__section-background"
                                    initial="visible"
                                    animate={controls}
                                    variants={imageVariants}
                        >
                            <img src={data.image}
                                 srcSet={`${data.image2x ? data.image2x + " 2x" : ""}`}
                                 alt={`Title: ${data.title}`} />
                        </motion.div>
                        <motion.div className="home__section-background home__section-background--graphics"
                        initial="visible"
                        animate={controls}
                        variants={imageVariants}
                        >
                            <img src={data.svg} style={{transform: "rotate("+data.svgrotate+"deg)"}} />
                        </motion.div>
                    </>
                )}
                <div className="home__section-content">
                    {isMobile ? (
                        <h2 className="heading heading--2 transform--uppercase home__section-title">
                            {data.type}
                        </h2>
                    ) : (
                        <motion.h2 className="heading heading--2 transform--uppercase home__section-title">
                            <AnimatedLetters word={data.type} />
                        </motion.h2>
                    )}
                    <motion.ul className="home__section-list" ref={ref} variants={dataListVariants} animate={controls}>
                        {data.stores.map((store, index) => (
                            <motion.li key={data.title + store.text + index} variants={dataListItemVariants} style={{
                                translateX: store.positionX,
                                translateY: store.positionY,
                                rotate: store.rotate
                            }}>
                                {store.text}
                                <Link to={store.url} className={`store-link store-link--${store.name}`}>
                                    <Icon name={store.name} classList=""/>
                                </Link>
                            </motion.li>
                        ))}
                    </motion.ul>
                </div>
            </section>
        </>
    );
}


const Home = () => {
    const { scrollYProgress } = useScroll();

    const slides = [
        {
            svg: '/images/nkor-bg.svg',
            svgrotate: -3,
            image:"../images/akkezdet-phi-ai-cover.min.png",
            image2x:"../images/akkezdet-phi-ai-cover@2x.min.png",
            type: "Single",
            title: 'AkkezdetPhiAi',
            path: '#',
            stores: [
                {
                    name: 'spotify-full',
                    url: 'https://open.spotify.com/album/7aFOopCs1LYHLdLFrmJHjv?si=WMCTJXzmT2WNAGCMOUkNrQ'
                },
                {
                    name: 'youtube-full',
                    url: 'https://l.instagram.com/?u=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DovaTOhb3ikE%26list%3DPLD1vf3fQ0J4-kBAMb3ITn9Qdm9BP5LWor%26index%3D1&e=AT371rF0MEr0bf_cvmaIpPlGzhKD1OZL4KyZ_5x-sgFDzaaGieei_iz5xi5jW8RDn7pHsSsgkfhMbCO8XVrvvTfKjdxeiUcXXJxql13JgRe5c4gagb4W4A'
                },
                {
                    name: 'apple-full',
                    url: 'https://music.apple.com/us/album/akkezdet-phi-ai-single/1748905105'
                }
            ]
        },
        {
            svg: '/images/nkor-bg.svg',
            svgrotate: -3,
            image:"../images/nkor-cover.min.png",
            image2x:"../images/nkor-cover@2x.min.png",
            type: "album",
            title: 'nKor',
            path: '#',
            stores: [
                {
                    name: 'spotify-full',
                    url: 'https://open.spotify.com/album/4TbcogogQSd9m2Ed3M5RuB?si=57YhyEPFTt2xRFuheIu30A'
                },
                {
                    name: 'youtube-full',
                    url: 'https://www.youtube.com/watch?v=UO69UG5qIxA&list=OLAK5uy_mVSAwGsF1qVBK6QaVMqjqyYx8DrDA2Z6w'
                },
                {
                    name: 'apple-full',
                    url: 'https://music.apple.com/us/album/nkor/1738723472'
                }
            ]
        },
        {
            svg: '/images/kiadhatatlan-bg.svg',
            svgrotate: 175,
            image:"../images/kiadhatatlan-cover.min.png",
            image2x:"../images/kiadhatatlan-cover@2x.min.png",
            type: "album",
            title: 'Kiadhatalan',
            path: '#',
            stores: [
                {
                    name: 'spotify-full',
                    url: 'http://spoti.fi/3dxYjHq'
                },
                {
                    name: 'youtube-full',
                    url: 'https://www.youtube.com/watch?v=OVTj392G470&list=PLD1vf3fQ0J4_mP16Z-RyyIjnSOWyddNei&index=1'
                },
                {
                    name: 'apple-full',
                    url: 'https://music.apple.com/us/album/kiadhatatlan/1557519418'
                }
            ]
        },
    ]

    return (
        <div className="page home">
            <motion.div
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <>
                    {slides.map((slide, index) => (
                        <Slide key={slide.title + index} data={slide} />
                    ))}
                    <section className="home__section home__section--follow home__section--active">
                        <div className="follow-bg hide--on-sm">
                            <span className="padding-lg--left">KövessKövessKövessKövessKövessKövess</span>
                            <span className="margin-xl--left--neg">KövessKövess<a href="https://open.spotify.com/artist/18103CROZuJlPhgs8rvxhW?si=jVedA7LQSdOA6C93D0AstQ">Spotify</a>KövessKövessKövess</span>
                            <span className="padding-hg--left">KövessKövessKövess<a href="https://youtube.com/enesenmusic">Youtube</a>KövessKövess</span>
                            <span>KövessKövessKövess<a href="https://music.apple.com/us/artist/%C3%A9n%C3%A9s%C3%A9n/1431674504">Apple Music</a>KövessKövessKövessKövess</span>
                            <span>KövessKövessKövessKövessKövessKövess</span>
                        </div>
                        <div className="follow-bg hide--on-lg hide--on-md show--on-sm">
                            <span>KövessKövessKövessKövessKövessKövess</span>
                            <span>KövessKövessKövessKövessKövess</span>
                            <span>KövessKövess<a href="https://open.spotify.com/artist/18103CROZuJlPhgs8rvxhW?si=jVedA7LQSdOA6C93D0AstQ">Spotify</a>KövessKövessKövess</span>
                            <span>KövessKövessKövess<a href="https://youtube.com/enesenmusic">Youtube</a>KövessKövess</span>
                            <span>KövessKövess<a href="https://music.apple.com/us/artist/%C3%A9n%C3%A9s%C3%A9n/1431674504">Apple Music</a>KövessKövess</span>
                            <span>KövessKövessKövessKövessKövessKövess</span>
                            <span>KövessKövessKövessKövessKövess</span>
                        </div>
                    </section>
                    <section className="home__section home__section--footer">
                        <Footer />
                    </section>
                </>
            </motion.div>
        </div>
    );
}

export default Home;
